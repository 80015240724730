{
  "action": {
    "add": "Add Video",
    "remove": "Remove"
  },
  "description": {
    "h1": "Note:",
    "link-text": "How-To Guide for Profile Videos",
    "p1": "Videos help facilitate better client-therapist matching. They are a critically important part of your therapist profile.",
    "p2": "A basic introductory video is a great place to start. This is a way to introduce yourself and address Client FAQs.",
    "p3": "Adding brief, 1-2 minute videos where you discuss your specialty areas in more detail will take your profile to the next level and help prospective clients see you as an expert.",
    "p4-p1": "Our directory only supports YouTube videos. But don’t worry! We will be happy to add your video to our Therapist Profiles YouTube Channel for you. Simply share the video with our team via a Google Drive or Dropbox link. Email that link to us at",
    "p4-p2": "with the subject line “New Profile Video.” If you wrote a script for the video, include that text in the email as well. We’ll take care of the rest.",
    "p5": "Our team optimizes all the videos on our Therapist Profiles YouTube Channel to ensure prospective clients have no trouble accessing them and that the videos can reach as wide an audience as possible.",
    "p6": "For more help practical tips and instruction on creating videos, visit our"
  },
  "form": {
    "file": "File Link (please provide YouTube link)",
    "script": "Script",
    "subtitle": "If you entered a file link, please fill in this field",
    "title": "Title",
    "video": "Video"
  },
  "title": "Videos"
}
