{
  "description": {
    "h1": "Email",
    "h2": "Password",
    "p1": "All communications from Choosing Therapy will be addressed to:",
    "p2": "If you need to change the email address on file, please contact us at",
    "p3": "To reset your password, enter a new password below, confirm it, and click \"Save Changes\".",
    "supportEmail": "directory.support@choosingtherapy.com"
  },
  "form": {
    "confirmNewPassword": "Confirm New Password",
    "currentPassword": "Current Password",
    "newPassword": "New Password"
  },
  "title": "Account Settings"
}
