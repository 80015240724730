{
  "description": {
    "p1": "Please enter your information in the Supervisee section. Please enter your supervisor's information below that. We will not contact your supervisor but we check to confirm their license is valid and in good standing.",
    "p2": "If you are not under supervision or no longer under supervision, please click the link at the bottom of the page to set up a profile as an independently licensed therapist."
  },
  "intern": {
    "registrationNumber": "Intern/Registration Number",
    "title": "Title",
    "workPlaceName": "Your Place of Employment / Volunteering"
  },
  "internHeader": "Supervisee",
  "notUnderSupervision": "I am not under supervision, add licenses",
  "popUp": {
    "cancel": "Cancel",
    "confirm": "Confirm",
    "description": "By changing this option the page will change to the License page, so the information about your supervisor will be lost.",
    "title": "Are you not under supervision anymore?"
  },
  "prompt": "Are you under supervision?",
  "supervisorHeader": "Supervisor",
  "title": "Supervision"
}
