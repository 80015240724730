{
  "add-slot": "add slot",
  "closed-scheduling": "closed scheduling",
  "closed-scheduling-description": "Restrict appointment start times and keep a tight schedule.",
  "description": {
    "p1": "Your general office hours will be displayed in the directory search results and at the top of your profile page. They should reflect your widest possible office hours for all locations.",
    "example": "Example:",
    "p2":"If you work 9am - 5pm but split your time between",
    "practice_1":"In-Person Office 1",
    "p3": "on M, W, and F and in ",
    "practice_2":"In-Person Office 2",
    "p4":" on T and Th, and on Saturdays you offer virtual sessions only, then your General Office Hours would be Monday - Saturday, 9am to 5 pm.",
    "note": "Note:",
    "p5": "You’ll have an opportunity to enter office-specific days and hours of operation in the Practice Details section of your profile."
  },
  "duration": "session duration",
  "hours-of-operation": "Hours of Operation",
  "hours-of-operation-description": "Daily hours must be between 12 am and 11:45 pm. Hours cannot span two days (e.g. 11 pm - 2 am). Please enter your hours of operation.",
  "invalid-interval": "The end time has to be later than the start",
  "open-scheduling": "open scheduling",
  "open-scheduling-description": "Fill your calendar quicker by letting new clients pick their ideal start time.",
  "range-end": "range end",
  "range-start": "range start",
  "remove-slot": "remove time slot",
  "slots-overlap": "Time slots can not overlap, please review.",
  "timezone": "Timezone",
  "title": "General Office Hours"
}
