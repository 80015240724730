{
  "choosing-therapy": "Choosing Therapy",
  "disclaimer": "Choosing Therapy is improving the quality of mental health content on the web, expanding access to therapy, and helping licensed mental health professionals launch and grow private practices. The Choosing Therapy Directory is not appropriate for those in need of immediate care. If you're experiencing an emergency, call 9-1-1.",
  "navlinks": {
    "about-us": "About us",
    "contact-us": "Contact Us",
    "crises-hotline": "Crisis Hotlines",
    "directory": "Directory",
    "faqs": "Directory FAQ",
    "login": "Therapist Login",
    "signup": "Therapist Signup",
    "terms-of-services": "Terms of Service"
  },
  "title": "footer",
  "toslinks": {
    "advertising-policy": "Advertising Policy",
    "editorial-policy": "Editorial Policy",
    "privacy-policy": "Privacy Policy",
    "terms-and-conditions": "Terms and Conditions"
  }
}
