{
  "action": {
    "disconnect": "Disconnect Account"
  },
  "description": {
    "h1": "NOTE:",
    "h2-google-p1": "Your Google account",
    "h2-outlook-p1": "Your Microsoft account",
    "h2-p2": "is connected!",
    "h3": "Select all relevant calendars",
    "link-text": "here",
    "link2-text": "Learn more",
    "p1": "Select the calendar you want to sync with your profile. Choosing Therapy will scan your synced calendar to find the open timeslots during your hours of operation. Only those open timeslots will be displayed on your profile.",
    "p2": "We do not read or share any calendar information other than if a timeslot is available or unavailable. We do not add or delete any information from your calendar.",
    "p3": "Find more Calendar Sync help",
    "p4": "Select all the calendars that may impact your availability during your standard hours of operation."
  },
  "modal": {
    "button-close": "Close",
    "button-unsync": "Unsync",
    "h1": "Are you sure you want to unsync your calendar?",
    "p1": "Unsyncing your calendar will disable real-time availability, a feature that adds tremendous value to your profile and the directory. Answers to common questions about calendar sync and real-time availability can be found"
  },
  "signin": {
    "google": "Sign in with Google",
    "outlook": "Sign in with Microsoft"
  },
  "title": "Calendar Sync"
}
