{
  "description": {
    "h2": "Why do we limit the number of specialties you can choose?",
    "p1": "You will see the same choices for Specialties and General Expertise. We ask you to choose 3-5 specialties and less than 20 areas of general expertise.",
    "p2": "We ask you to choose only those specialties that match your ideal client, the issues you work best with, or that you have the most experience with. By limiting the number of specialties that therapists can highlight, prospective clients can more easily identify the professionals that might be a good option for them."
  },
  "form": {
    "clientele": "Clientele",
    "generalExpertise": "General Expertise (pick up to 20)",
    "modalities": "Treatment Approaches / Modalities",
    "specialties": "Specialties (pick up to 5)"
  },
  "title": "Specialties and Expertise"
}
