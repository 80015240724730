{
  "acceptingInpersonInsurance": "Do you accept insurance here?",
  "acceptingNewInpersonClients": "Are you accepting new clients here?",
  "acceptingNewTelehealthClients": "Are you accepting new telehealth clients?",
  "acceptingTelehealthInsurance": "Do you accept insurance for telehealth?",
  "adaAccessible": "ADA accessible?",
  "description": {
    "p1": "Please add your virtual and in-person practice details. These details play a critical role in surfacing your profile for prospective clients. We allow providers to list only one virtual practice  but do not limit the number of physical locations providers can list.",
    "p2": "First, you’ll be prompted to add basic details. Once saved, you’ll be asked for supplementary details. The more details you can provide about each practice venue, the better. Potential clients will be left with fewer questions and that will help put them at ease as they consider booking an appointment."
  },
  "detailsOffice": "Important Details About This Office",
  "detailsOfficeDescription": "Please provide any details about this particular location that may be important for a potential client to know (i.e. changes in services provided, changes in insurance, special virtual tools used, device/software requirements or tips).",
  "fees-for": "Fees for",
  "hasParking": "Does this location have parking?",
  "hasWaitingRoom": "Does this location have a waiting room?",
  "in-person": {
    "add": "Add In-person Practice",
    "defaultTitle": "In-person",
    "gallery": {
      "animal": "Emotional Support Animal",
      "disclaimer": "Note: You must click “Save” to add the photo to your practice.",
      "exterior": "Exterior Office",
      "interior": "Interior Office",
      "title": "Photos for Location",
      "waiting": "Waiting Room"
    },
    "locationDescription": "This address will be listed on your profile.",
    "officeHoursPrompt": "What are this location's typical office hours?",
    "servicesLabel": "Which services do you offer here?"
  },
  "liveMessaging": "Do you offer live messaging or text-based sessions?",
  "online": {
    "add": "Add Online Therapy",
    "office-phone": "Office phone number",
    "servicesToOffer": "Which services do you offer via telehealth?",
    "telehealth": "Telehealth",
    "telehealthOfficeHours": "What are your telehealth office hours?",
    "title": "Practice - Online Therapy",
    "website": "Website"
  },
  "phoneSessions": "Do you offer sessions over the phone?",
  "pop-up": {
    "cancel": "Cancel",
    "delete": "Delete",
    "deletingPractice": "Deleting practice",
    "h1": "Are you sure you want to delete that practice?",
    "p1": "By clicking this button you will delete all information about this practice. There’s no opportunity to restore this information."
  },
  "practice": "practice",
  "practice-details": "Practice Details",
  "services": "Fees and Services",
  "videoSessions": "Do you offer video sessions?"
}
