{
  "copied-profile": "Profile Link Copied",
  "copy-profile": "Copy Profile Link",
  "see-profile": "See My Profile",
  "status": "Status",
  "statuses": {
    "pending": "Under review",
    "rejected": "Rejected",
    "verified": "Verified"
  }
}
