{
  "copy-link": "Copy Profile Link",
  "description": {
    "PENDING-message": "While our team verifies your licensing information (typically takes 2 business days) you can fill out practice details, add photos, and complete your brand new profile page.",
    "PENDING-cache-message": "Once your profile is approved, it can take up to 7 days for your profile to appear in the directory search results.",
    "PENDING-title": "Excellent! Time to complete your profile!",
    "p1": "Your therapist profile is a chance for prospective clients to get to know you and your practice before requesting an appointment. The more robust your profile, the better it will perform.",
    "p2": "A good therapist profile will have introductory videos and photos. It will also have thorough responses to prompts that address potential clients’ frequently asked questions and highlight your experience and expertise. In other words, a good profile will help you establish trust and rapport with potential clients before you’ve ever met.",
    "p3": "Please create unique content for your Choosing Therapy profile (i.e. do not copy content from a Psychology Today profile). Duplicated content does not perform well on the web, whereas new and unique content is rewarded.",
    "p4": "Some fields (i.e. license information) require a manual review and approval by our team. Those changes may not be reflected on your public profile immediately."
  },
  "form": {
    "ethnicityDescription": "Select ethnic identities that apply to you",
    "facebook": "Facebook",
    "faithsDescription": "Select faiths you identify as holding",
    "firstName": "First Name",
    "genderDescription": "Select genders that apply to you",
    "groupIdentities": "Group Identities",
    "groupIdentitiesDescription": "Select groups you identify as belonging to",
    "honorific": "Honorific",
    "honorificDescription": "Credentials you’d like to appear before your name (e.g. Dr, Mr)",
    "identity": "Identity",
    "identityDisclaimer": "if you choose something in this section it will be visible on your profile",
    "instagram": "Instagram",
    "languagesDescription": "What languages are you able to hold sessions in? ",
    "lastName": "Last Name",
    "linkedin": "LinkedIn",
    "middleName": "Middle Name",
    "npi": "NPI#",
    "officeLocation": "Office Location",
    "officeLocation-description": "This address will be listed on your profile. If you do not currently see clients in-person, you may simply enter your City, State, and Zip.",
    "otherIdentities": "Other Identities",
    "otherIdentitiesDescription": "Select other identities that apply to you",
    "pronouns": "Pronouns",
    "pronounsDescription": "Pronouns you’d like to appear below your name (e.g. she/her)",
    "sexualityDescription": "Select sexualities that apply to you",
    "social": "Social",
    "social-description": "Provide links to your professional profiles on social media.",
    "tiktok": "TikTok",
    "title": "General Information",
    "twitter": "Twitter",
    "typeMyOwn": "I prefer to type on my own",
    "yearStartedPracticing": "Year Started Practicing",
    "youtube": "YouTube"
  },
  "profile-link": "Profile Link",
  "profile-link-description": "You can easily copy your profile link and share it with your followers or community."
}
