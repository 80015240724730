{
  "description": {
    "disclaimer": {
      "h1": "Note:",
      "p1": "Enter your fee as an integer with no special symbols (e.g. 85, 110, 160, etc.)."
    },
    "p1": "Choosing Therapy enables users to filter therapists based on cost and insurance.",
    "p2": "The ranges of our cost filter are: All Price Ranges; Less Than $120;  Less Than $200; and Less Than $300. The filters are based on your First Session rate.",
    "p3": "Please consider that the more reasonable a fee is, the more prospective clients will be attracted to your profile."
  },
  "form": {
    "acceptedInsurance": "Accepted Insurance",
    "firstSession": "Fees for First Session / Assessment",
    "typesOfTherapy": "Types Of Therapy",
    "insurance": "Insurances",
    "feesAndTypesOfTherapy": "Fees and Types of Therapy",
    "feesFor": "Fees for"
  },
  "insuranceDescription": {
    "email": "directory.support@choosingtherapy.com",
    "p1": "Please select all the insurance plans you accept.",
    "p2": "Don't see an insurance you accept? Email us at "
  },
  "title": "Fees and Insurances"
}
