{
  "description": "Provide an email, we will send you a letter with a magic link",
  "form": {
    "email": "Email"
  },
  "reset-password-button": "Reset Password",
  "sign-up-link": "Sign Up",
  "sing-up-description": "Don’t have an account?",
  "success-message": "We have sent you an email with further instructions. If you don't see it, check your spam folder and make sure that you submitted your email correctly.",
  "title": "Password Reset"
}
