import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import React, { useEffect } from "react";
import { hydrate } from "react-dom";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { getInitialNamespaces } from "remix-i18next";
import officeHours from "../public/locales/en/office-hours.json";
import educations from "../public/locales/en/educations-certifications.json";
import fees from "../public/locales/en/fees.json";
import general from "../public/locales/en/general.json";
import generalInfo from "../public/locales/en/general-info.json";
import licenses from "../public/locales/en/licenses.json";
import login from "../public/locales/en/login.json";
import footer from "../public/locales/en/page-footer.json";
import header from "../public/locales/en/page-header.json";
import question from "../public/locales/en/question.json";
import specialties from "../public/locales/en/specialties.json";
import videos from "../public/locales/en/videos.json";
import gallery from "../public/locales/en/gallery.json";
import accountSetting from "../public/locales/en/account-setting.json";
import calendarSync from "../public/locales/en/calendar-sync.json";
import signup from "../public/locales/en/signup.json";
import resetPassword from "../public/locales/en/reset-password.json";
import forgotPassword from "../public/locales/en/forgot-password.json";
import sidebar from "../public/locales/en/sidebar.json";
import supervision from "../public/locales/en/supervision.json";
import practiceDetails from "../public/locales/en/practice-details.json";
import * as Sentry from "@sentry/remix";

const i18nResources = {
  en: {
    "office-hours": officeHours,
    "educations-certifications": educations,
    fees,
    general,
    "general-info": generalInfo,
    licenses,
    login,
    "page-footer": footer,
    "page-header": header,
    question,
    specialties,
    gallery,
    videos,
    "account-setting": accountSetting,
    "calendar-sync": calendarSync,
    signup,
    "reset-password": resetPassword,
    "forgot-password": forgotPassword,
    "practice-details": practiceDetails,
    sidebar,
    supervision,
  },
};

i18next
  .use(initReactI18next) // Tell i18next to use the react-i18next plugin
  .use(LanguageDetector) // Setup a client-side language detector
  .use(Backend) // Setup your backend
  .init({
    // This is normal i18next config, except a few things
    supportedLngs: ["es", "en"],
    defaultNS: "general",
    fallbackLng: "en",
    // Disabling suspense is recommended
    react: { useSuspense: false },
    // This function detects the namespaces your routes rendered while SSR use
    // and pass them here to load the translations
    ns: getInitialNamespaces(),
    backend: { loadPath: "/locales/{{lng}}/{{ns}}.json" },
    resources: i18nResources,
    detection: {
      // Here only enable htmlTag detection, we'll detect the language only
      // server-side with remix-i18next, by using the `<html lang>` attribute
      // we can communicate to the client the language detected server-side
      order: ["htmlTag"],
      // Because we only use htmlTag, there's no reason to cache the language
      // on the browser, so we disable it
      caches: [],
    },
  })
  .then(() => {
    // After i18next has been initialized, we can hydrate the app
    // We need to wait to ensure translations are loaded before the hydration
    // Here wrap RemixBrowser in I18nextProvider from react-i18next
    return hydrate(
      <React.StrictMode>
        <I18nextProvider i18n={i18next}>
          <RemixBrowser />
        </I18nextProvider>
      </React.StrictMode>,
      document
    );
  });

Sentry.init({
  dsn: (window as any).ENV.SENTRY_DSN,
  tracesSampleRate: (window as any).ENV.SENTRY_TRACE_RATE,
  environment: (window as any).ENV.SENTRY_ENVIRONMENT,
  release: (window as any).ENV.SENTRY_RELEASE,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(
        useEffect,
        useLocation,
        useMatches
      ),
    }),
  ],
});
