{
  "add-education": "Add Education",
  "add-training-certification": "Add Training/Certifications",
  "education": "Education",
  "education-description-p1": "Please include all degrees you hold, starting with the most recent degree obtained.",
  "education-description-p2": "Format in the following order: Degree, Subject Area, School, Year Obtained",
  "title": "Education and Certifications",
  "training-certification": "Training or Certification",
  "training-certifications": "Training / Certifications",
  "training-certifications-description": "Please include all professional trainings and certifications that are relevant to your current work.",
  "degree": "Degree",
  "school": "School",
  "description": "Description",
  "description-description": "Please add information about this degree that may be helpful to prospective clients. This may include details about research, the topic of a thesis, academic awards earned, or groups participated in.",
  "subjectArea": "Subject Area",
  "graduationYear": "Year Graduated"
}
