{
  "description": "Please list all state-held clinical licenses that authorize you to provide therapy independently.",
  "licenseForm": {
    "addLicense": "Add License",
    "expirationDate": "Expiration Date",
    "license#": "License #",
    "licenseName": "License Name",
    "licenseNumber": "License Number",
    "licenseTitle": "License",
    "licensure": "Licensure",
    "removeLicense": "Remove",
    "state": "State"
  },
  "title": "Licenses"
}
