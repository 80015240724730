{
  "accountSettings": "Account Settings",
  "general-office-hours": "General Office Hours",
  "calendar-sync": "Calendar Sync",
  "educationCertification": "Education and Certifications",
  "feesTypesTherapy": "Fees and Insurances",
  "gallery": "Photo Gallery",
  "generalInfo": "General Information",
  "helpCenter": "Help Center",
  "licensesTitlesStates": "Licenses",
  "logout": "Log Out",
  "practiceDetails": "Practice Details",
  "profile": "Profile",
  "specialtyExpertise": "Specialties and Expertise",
  "supervision": "Supervision",
  "therapistProfileQuestions": "Therapist Profile Questions",
  "videos": "Videos",
  "identity": "Identity"
}
